'use client';

import { KheradmenLogoWithText } from '@/components/icons/logo';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { motion } from 'framer-motion';
import { Instagram, Linkedin, Send, Twitter } from 'lucide-react';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import React, { useMemo } from 'react';
type FooterLinkProps = {
  href: string;
  children: React.ReactNode;
  isExternal?: boolean;
};
type SocialLinkProps = {
  href: string;
  icon: React.ReactElement;
  label: string;
};
type FooterSectionProps = {
  title: string;
  links: Array<{
    id: string;
    href: string;
    isExternal?: boolean;
    isEmail?: boolean;
    isPhone?: boolean;
  }>;
};
const fadeInUp = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    opacity: 1,
    y: 0
  },
  transition: {
    duration: 0.5,
    ease: 'easeOut'
  }
};
const FooterLink: React.FC<FooterLinkProps> = ({
  href,
  children,
  isExternal
}) => {
  const locale = useLocale();
  const isRTL = locale === 'fa';
  return <motion.div whileHover={{
    x: isRTL ? -5 : 5
  }} whileTap={{
    scale: 0.95
  }} style={{
    direction: isRTL ? 'rtl' : 'ltr'
  }} data-sentry-element="unknown" data-sentry-component="FooterLink" data-sentry-source-file="footer.tsx">
			<Link href={href} className="text-muted-foreground hover:text-primary transition-colors duration-300" {...isExternal ? {
      target: '_blank',
      rel: 'noopener noreferrer'
    } : {}} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
				{children}
			</Link>
		</motion.div>;
};
const SocialLink: React.FC<SocialLinkProps> = ({
  href,
  icon,
  label
}) => <motion.div whileHover={{
  scale: 1.1
}} whileTap={{
  scale: 0.9
}} data-sentry-element="unknown" data-sentry-component="SocialLink" data-sentry-source-file="footer.tsx">
		<Button variant="ghost" size="icon" asChild className="rounded-full p-2 hover:bg-primary/10" data-sentry-element="Button" data-sentry-source-file="footer.tsx">
			<Link href={href} aria-label={label} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="footer.tsx">
				{React.cloneElement(icon, {
        'aria-hidden': 'true',
        className: 'h-5 w-5'
      })}
			</Link>
		</Button>
	</motion.div>;
const FooterSection: React.FC<FooterSectionProps> = ({
  title,
  links
}) => <motion.div className="space-y-4" {...fadeInUp} data-sentry-element="unknown" data-sentry-component="FooterSection" data-sentry-source-file="footer.tsx">
		<h3 className="font-medium text-sm uppercase tracking-wider text-primary/80">
			{title}
		</h3>
		<ul className="space-y-2">
			{links.map(link => <li key={link.id}>
					<FooterLink href={link.href} isExternal={link.isExternal}>
            <span className="text-sm">
              {link.isEmail || link.isPhone ? <span dir="ltr">{link.id}</span> : link.id}
            </span>
					</FooterLink>
				</li>)}
		</ul>
	</motion.div>;
const Footer: React.FC = () => {
  const t = useTranslations('Footer');
  const footerSections = useMemo(() => [{
    title: t('links.title'),
    links: [{
      id: t('links.articles'),
      href: '/blog'
    }, {
      id: t('links.robovakil'),
      href: t('links.robovakil'),
      isExternal: true
    }, {
      id: t('links.medguard'),
      href: t('links.medguard'),
      isExternal: true
    }, {
      id: t('links.hami'),
      href: t('links.hami'),
      isExternal: true
    }, {
      id: t('links.carestyle'),
      href: t('links.carestyle'),
      isExternal: true
    }]
  }, {
    title: t('legal.title'),
    links: [{
      id: t('legal.cookie-policy'),
      href: '/cookie-policy'
    }, {
      id: t('legal.privacy-policy'),
      href: '/privacy-policy'
    }, {
      id: t('legal.terms-of-service'),
      href: '/terms-of-service'
    }]
  }, {
    title: t('contact.title'),
    links: [{
      id: t('contact.email'),
      href: 'mailto:hello@kheradmen.com',
      isEmail: true
    }, {
      id: t('contact.phone'),
      href: 'tel:+982191031969',
      isPhone: true
    }, {
      id: t('contact.address'),
      href: '#'
    }]
  }], [t]);
  const socialLinks = useMemo(() => [{
    href: 'https://twitter.com/kheradmen',
    icon: <Twitter />,
    label: t('social.twitter')
  }, {
    href: 'https://www.linkedin.com/company/kheradmen',
    icon: <Linkedin />,
    label: t('social.linkedin')
  }, {
    href: 'https://t.me/Kheradmen',
    icon: <Send />,
    label: t('social.telegram')
  }, {
    href: 'https://instagram.com/kheradmenn',
    icon: <Instagram />,
    label: t('social.instagram')
  }], [t]);
  return <footer className="bg-background" data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
			<div className="container py-16 px-4 md:px-8">
				<motion.div className="mx-auto max-w-6xl" initial="initial" animate="animate" variants={{
        initial: {
          opacity: 0
        },
        animate: {
          opacity: 1,
          transition: {
            staggerChildren: 0.1
          }
        }
      }} data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
					<motion.div className="flex justify-center mb-16" variants={{
          initial: fadeInUp.initial,
          animate: fadeInUp.animate
        }} transition={fadeInUp.transition} data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
						<KheradmenLogoWithText className="h-8 w-auto" data-sentry-element="KheradmenLogoWithText" data-sentry-source-file="footer.tsx" />
					</motion.div>

					<motion.div className="grid grid-cols-1 md:grid-cols-3 gap-12 mb-16" variants={{
          initial: {
            opacity: 0
          },
          animate: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1
            }
          }
        }} data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
						{footerSections.map((section, index) => <motion.div key={index} variants={{
            initial: fadeInUp.initial,
            animate: fadeInUp.animate
          }} transition={fadeInUp.transition}>
								<FooterSection {...section} />
							</motion.div>)}
					</motion.div>

					<Separator className="mb-8 opacity-10" data-sentry-element="Separator" data-sentry-source-file="footer.tsx" />

					<motion.div className="flex flex-col md:flex-row justify-between items-center" variants={{
          initial: fadeInUp.initial,
          animate: fadeInUp.animate
        }} transition={fadeInUp.transition} data-sentry-element="unknown" data-sentry-source-file="footer.tsx">
						<div className="flex space-x-4 rtl:space-x-reverse mb-6 md:mb-0">
							{socialLinks.map((link, index) => <SocialLink key={index} {...link} />)}
						</div>
						<p className="text-xs text-muted-foreground text-center md:text-right">
							© {new Date().getFullYear()}{' '}
							<span className="font-medium">KHERADMEN</span>. {t('rights')}
						</p>
					</motion.div>
				</motion.div>
			</div>
		</footer>;
};
export default Footer;