import { CookieKey, CookieValueMap, getCookieValue } from '@/cookies/types';
import { useEffect, useState }                       from 'react';



type CookieOptions = {
	maxAge?: number;
	expires?: Date;
	path?: string;
	domain?: string;
	secure?: boolean;
	httpOnly?: boolean;
	sameSite?: 'strict' | 'lax' | 'none';
};

export function useCookie<K extends CookieKey>(
	key: K,
	initialValue: CookieValueMap[K]
): [CookieValueMap[K], (newValue: CookieValueMap[K], options?: CookieOptions) => void] {
	const [value, setValue] = useState<CookieValueMap[K]>(() => {
		if (typeof window === 'undefined') {
			return initialValue;
		}
		try {
			const item = document.cookie
			.split('; ')
			.find((row) => row.startsWith(key + '='));
			if (item) {
				const cookieValue = getCookieValue(key, item.split('=')[1]);
				return cookieValue !== undefined ? cookieValue : initialValue;
			}
			return initialValue;
		} catch (error) {
			console.error('Error reading cookie:', error);
			return initialValue;
		}
	});

	const setCookie = (newValue: CookieValueMap[K], options: CookieOptions = {}) => {
		try {
			const optionsWithDefaults = {
				path: '/',
				...options
			};
			const expires = optionsWithDefaults.expires
				? optionsWithDefaults.expires.toUTCString()
				: new Date(Date.now() + (optionsWithDefaults.maxAge || 7 * 24 * 60 * 60 * 1000)).toUTCString();

			const newCookie = [
				`${ key }=${ String(newValue) }`,
				`expires=${ expires }`,
				`path=${ optionsWithDefaults.path }`,
				optionsWithDefaults.domain ? `domain=${ optionsWithDefaults.domain }` : '',
				optionsWithDefaults.sameSite ? `SameSite=${ optionsWithDefaults.sameSite }` : '',
				optionsWithDefaults.secure ? 'Secure' : '',
				optionsWithDefaults.httpOnly ? 'HttpOnly' : ''
			].filter(Boolean).join('; ');

			document.cookie = newCookie;
			setValue(newValue);
		} catch (error) {
			console.error('Error setting cookie:', error);
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const storedValue = document.cookie
			.split('; ')
			.find((row) => row.startsWith(key + '='));
			if (storedValue) {
				const cookieValue = getCookieValue(key, storedValue.split('=')[1]);
				if (cookieValue !== undefined) {
					setValue(cookieValue);
				}
			}
		}
	}, [key]);

	return [value, setCookie];
}