'use client';

import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import { CookieKeys, ThemeValue } from '@/cookies/types';
import { useCookie } from '@/hooks/use-cookie';
import { useMounted } from '@/hooks/use-mounted';
import { Monitor, Moon, Sun } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { useTheme } from 'next-themes';
import React from 'react';
const themeOptions: {
  theme: ThemeValue;
  icon: React.ReactElement;
  labelId: string;
}[] = [{
  theme: 'light',
  icon: <Sun />,
  labelId: 'light'
}, {
  theme: 'dark',
  icon: <Moon />,
  labelId: 'dark'
}, {
  theme: 'system',
  icon: <Monitor />,
  labelId: 'system'
}];
const ModeToggle: React.FC = () => {
  const t = useTranslations('ModeToggle');
  const {
    setTheme,
    theme
  } = useTheme();
  const [cookie, setCookie] = useCookie(CookieKeys.Theme, 'system');
  const isMounted = useMounted();
  React.useEffect(() => {
    if (cookie && cookie !== theme) {
      setTheme(cookie);
    }
  }, [cookie, setTheme, theme]);
  const handleSetTheme = (newTheme: ThemeValue) => {
    setTheme(newTheme);
    setCookie(newTheme, {
      path: '/'
    });
  };
  if (!isMounted) {
    return <Skeleton className="h-9 w-9" aria-label={t('themeToggleLoading')} />;
  }
  const currentIcon = themeOptions.find(option => option.theme === theme)?.icon || <Sun />;
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="ModeToggle" data-sentry-source-file="mode-toggle.tsx">
			<DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="mode-toggle.tsx">
				<Button variant="ghost" size="sm" className="h-9 w-9 px-0 hover:bg-accent" aria-label={t('toggleTheme')} data-sentry-element="Button" data-sentry-source-file="mode-toggle.tsx">
					{React.cloneElement(currentIcon, {
          className: 'h-5 w-5 text-foreground transition-colors',
          'aria-hidden': 'true'
        })}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-36" data-sentry-element="DropdownMenuContent" data-sentry-source-file="mode-toggle.tsx">
				{themeOptions.map(({
        theme: themeOption,
        icon,
        labelId
      }) => <DropdownMenuItem key={themeOption} onClick={() => handleSetTheme(themeOption)} className="flex items-center gap-2">
						{React.cloneElement(icon, {
          className: `h-4 w-4 ${theme === themeOption ? 'text-primary' : 'text-muted-foreground'}`,
          'aria-hidden': 'true'
        })}
						<span className={theme === themeOption ? 'font-medium' : ''}>
              {t(labelId as ThemeValue)}
            </span>
					</DropdownMenuItem>)}
			</DropdownMenuContent>
		</DropdownMenu>;
};
export default ModeToggle;