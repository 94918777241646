import { Locale } from '@/i18n-config';

// Cookie keys as const objects for better type inference
export const CookieKeys = {
	Consent: 'COOKIE_CONSENT',
	ConsentVersion: 'COOKIE_CONSENT_VERSION',
	ConsentExpiration: 'COOKIE_CONSENT_EXPIRATION',
	Theme: 'NEXT_THEME',
	Locale: 'NEXT_LOCALE',
	Session: 'NEXT_SESSION'
} as const;

// Type for all cookie keys
export type CookieKey = typeof CookieKeys[keyof typeof CookieKeys];

// Specific value types
export type CookieConsentValue = 'accepted' | 'rejected' | null;
export type ThemeValue = 'light' | 'dark' | 'system';
export type LocaleValue = Locale;

// Version control
export const COOKIE_CONSENT_VERSIONS = ['1.0.0'] as const;
export type CookieConsentVersionValue = typeof COOKIE_CONSENT_VERSIONS[number];

// Map cookie keys to their respective value types
export type CookieValueMap = {
	[CookieKeys.Consent]: CookieConsentValue;
	[CookieKeys.ConsentVersion]: CookieConsentVersionValue;
	[CookieKeys.ConsentExpiration]: string; // ISO date string
	[CookieKeys.Theme]: ThemeValue;
	[CookieKeys.Locale]: LocaleValue;
	[CookieKeys.Session]: string;
};

// Union type of all possible cookie values
export type CookieValue = CookieValueMap[CookieKey];

// Type-safe function to get the correct value type for a given key
export function getCookieValue<K extends CookieKey>(key: K, value: string | undefined): CookieValueMap[K] | undefined {
	if (value === undefined) return undefined;
	switch (key) {
		case CookieKeys.Consent:
			return value as CookieValueMap[K];
		case CookieKeys.ConsentVersion:
			return value as CookieValueMap[K];
		case CookieKeys.ConsentExpiration:
			return value as CookieValueMap[K];
		case CookieKeys.Theme:
			return value as CookieValueMap[K];
		case CookieKeys.Locale:
			return value as CookieValueMap[K];
		default:
			throw new Error(`Unknown cookie key: ${ key }`);
	}
}