'use client';

import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Locale } from '@/i18n-config';
import { motion } from 'framer-motion';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
const LocaleToggle = () => {
  const t = useTranslations('LocaleToggle');
  const pathname = usePathname();
  const currentLocale = useLocale() as Locale;
  const targetLocale: Locale = currentLocale === 'en' ? 'fa' : 'en';

  // Function to get the new pathname with the target locale
  const getNewPathname = () => {
    const segments = pathname.split('/');
    if (segments[1] === currentLocale) {
      segments[1] = targetLocale;
    } else {
      segments.splice(1, 0, targetLocale);
    }
    return segments.join('/');
  };
  return <TooltipProvider data-sentry-element="TooltipProvider" data-sentry-component="LocaleToggle" data-sentry-source-file="locale-toggle.tsx">
			<Tooltip data-sentry-element="Tooltip" data-sentry-source-file="locale-toggle.tsx">
				<TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="locale-toggle.tsx">
					<motion.div whileTap={{
          scale: 0.95
        }} data-sentry-element="unknown" data-sentry-source-file="locale-toggle.tsx">
						<Button variant="ghost" size="icon" className="font-semibold text-sm flex items-center justify-center" asChild data-sentry-element="Button" data-sentry-source-file="locale-toggle.tsx">
							<Link href={getNewPathname()} hrefLang={targetLocale} lang={targetLocale} data-sentry-element="Link" data-sentry-source-file="locale-toggle.tsx">
								{targetLocale.toUpperCase()}
							</Link>
						</Button>
					</motion.div>
				</TooltipTrigger>
				<TooltipContent side="bottom" align="center" data-sentry-element="TooltipContent" data-sentry-source-file="locale-toggle.tsx">
					<p className="text-sm">
						{t('switchTo', {
            locale: targetLocale === 'en' ? 'English' : 'Persian'
          })}
					</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>;
};
export default LocaleToggle;