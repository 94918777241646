'use client';

import KheradmenLogo, { KheradmenTextLogo } from '@/components/icons/logo';
import LocaleToggle from '@/components/navbar/locale-toggle';
import ModeToggle from '@/components/navbar/mode-toggle';
import NavigationLink from '@/components/navlinks/NavigationLink';
import { Pages } from '@/i18n-config';
import { cn } from '@/lib/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { useLocale, useTranslations } from 'next-intl';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { FC, HTMLAttributes, MouseEvent, useCallback, useEffect, useState } from 'react';
interface NavigationItem {
  href: string;
  label: Pages;
}
interface NavigationBarProps extends HTMLAttributes<HTMLDivElement> {
  NavigationItems: NavigationItem[];
}
const NavigationBar: FC<NavigationBarProps> = ({
  NavigationItems,
  className
}) => {
  const locale = useLocale();
  const t = useTranslations('Navigation');
  const pathname = usePathname();
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 10);
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const toggleMenu = () => setIsMenuOpen(prev => !prev);
  const NavigationLinks: FC<{
    Mobile?: boolean;
  }> = ({
    Mobile
  }) => {
    const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>, href: string) => {
      if (href.startsWith('#')) {
        e.preventDefault();
        const target = document.querySelector(href);
        target?.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }, []);
    return <ul className={cn('flex', Mobile ? 'flex-col space-y-4' : 'space-x-6 rtl:space-x-reverse')} data-sentry-component="NavigationLinks" data-sentry-source-file="NavigationBar.tsx">
			{NavigationItems.map(({
        href,
        label
      }) => <li key={href}>
					<NavigationLink href={href} className={cn('text-sm font-medium transition-colors duration-200', pathname.endsWith(href) ? 'text-foreground' : 'text-foreground/90 hover:text-primary', Mobile && 'text-lg')} onClick={(e: MouseEvent<HTMLAnchorElement>) => handleClick(e, href)}>
						{t(label)}
					</NavigationLink>
				</li>)}
		</ul>;
  };
  return <header className={cn('fixed top-0 left-0 right-0 z-50 transition-all duration-300 py-4', scrolled || isMenuOpen ? 'bg-background/90 backdrop-blur-xl shadow-sm' : 'bg-transparent', !scrolled && !isMenuOpen && 'pt-12', className)} data-sentry-component="NavigationBar" data-sentry-source-file="NavigationBar.tsx">
		<div className="container flex items-center justify-between px-4 md:px-8">
			<div className="flex items-center space-x-8 rtl:space-x-reverse">
				<Link href={`/${locale}`} className="flex-shrink-0" aria-label="Home" data-sentry-element="Link" data-sentry-source-file="NavigationBar.tsx">
					{locale === 'en' ? <KheradmenTextLogo className="h-4 w-auto transition-colors duration-200 fill-foreground/90" aria-hidden="true" /> : <KheradmenLogo className="h-10 w-auto transition-colors duration-200 fill-foreground/90" aria-hidden="true" />}
				</Link>
				<nav className="hidden md:flex" aria-label="Main navigation">
					<NavigationLinks data-sentry-element="NavigationLinks" data-sentry-source-file="NavigationBar.tsx" />
				</nav>
			</div>

			<div className="flex items-center space-x-2 rtl:space-x-reverse">
				<LocaleToggle data-sentry-element="LocaleToggle" data-sentry-source-file="NavigationBar.tsx" />
				<ModeToggle data-sentry-element="ModeToggle" data-sentry-source-file="NavigationBar.tsx" />
				<button className="md:hidden text-foreground" onClick={toggleMenu} aria-label="Toggle menu">
					{isMenuOpen ? <X size={24} /> : <Menu size={24} />}
				</button>
			</div>

		</div>

		<AnimatePresence data-sentry-element="AnimatePresence" data-sentry-source-file="NavigationBar.tsx">
			{isMenuOpen && <motion.div initial={{
        opacity: 0,
        y: -20
      }} animate={{
        opacity: 1,
        y: 0
      }} exit={{
        opacity: 0,
        y: -20
      }} transition={{
        duration: 0.3
      }} className="md:hidden bg-background/95 backdrop-blur-xl absolute top-full left-0 right-0 p-4 shadow-md">
					<NavigationLinks Mobile />
				</motion.div>}
		</AnimatePresence>
	</header>;
};
NavigationBar.displayName = 'Navigation';
export default NavigationBar;